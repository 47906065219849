import styled from "styled-components";

export const ButtonYellow = styled.button`
  background-color: #f37a1d;
  color: #18365a;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  border-width: 2px;
  border-color: #f37a1d;
  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 0.75rem;

  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  &:hover {
    background-color: #18365a;
    color: #f37a1d;
  }
`;
