import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const jwt = localStorage.getItem("token");
const constructApiUrl = (params) => {
  let {
    current = 1,
    pageSize = 10,
    text = "",
    reserved = false,
    free = false,
    status = null,
    deepNumber = 4,
    user = null,
    startDate = null,
    endDate = null,
    sortBy = null,
    user_id = null,
  } = params;

  const apiUrl = new URL(`${process.env.REACT_APP_BACKUP_URL}vehicules`);
  //
  apiUrl.searchParams.append("pLevel", `${deepNumber}`);
  //
  apiUrl.searchParams.append("pagination[pageSize]", 10);
  //
  apiUrl.searchParams.append("pagination[page]", current);
  //

  // if (user) {
  //   apiUrl.searchParams.append("filters[company_id][id][$eq]", user);
  // }
  // //

  if (status) {
    apiUrl.searchParams.append(
      "filters[validation][validation_state][$eq]",
      status
    );
  }

  //  [model][year][matriculation][vinNumber][assuranceDate]
  // apiUrl.searchParams.append("filters[$or][0][mark][$contains]", text);
  // apiUrl.searchParams.append("filters[$or][1][model][$contains]", text);

  // apiUrl.searchParams.append("filters[$or][2][year][$contains]", text);
  // apiUrl.searchParams.append("filters[$or][3][matriculation][$contains]", text);
  // apiUrl.searchParams.append("filters[$or][4][vinNumber][$contains]", text);
  // apiUrl.searchParams.append("filters[$or][5][assuranceDate][$contains]", text);
  // //
  // apiUrl.searchParams.append(
  //   "filters[$or][1][dropOfAddress][Address][$contains]",
  //   text
  // );
  // if (startDate) {
  //   apiUrl.searchParams.append("filters[$and][0][departDate][$gte]", startDate);
  // }
  // if (endDate) {
  //   apiUrl.searchParams.append("filters[$and][1][departDate][$lte]", endDate);
  // }
  if (user_id) {
    apiUrl.searchParams.append("filters[company_id][id][$eq]", user_id);
  }

  //${process.env.REACT_APP_BACKUP_URL}commands?filters[departDate][$gte][0]=2023-07-14&filters[departDate][$lte][1]=2023-07-20
  // apiUrl.searchParams.append("sort", ":desc");
  return apiUrl.toString();
};

// Function to fetch data
const fetchData = async (url) => {
  const jwt = localStorage.getItem("token");

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// get all vehicules
export const getVehicule = createAsyncThunk(
  "vehicule/all",
  async (parametre) => {
    const {
      pagination = {},
      text = "",
      reserved = false,
      free = false,
      status = null,
      deepNumber = 3,
      user = null,
      startDate = null,
      endDate = null,
      sortBy = null,
      user_id = null,
    } = parametre || {};

    const { current = 1, pageSize = 10 } = pagination || {};

    const apiUrl = constructApiUrl({
      current,
      pageSize,
      text,
      reserved,
      free,
      status,
      deepNumber,
      user,
      startDate,
      endDate,
      sortBy,
      user_id,
    });

    try {
      const data = await fetchData(apiUrl);

      return { data: data, etat: free };
    } catch (error) {}
  }
);

export const getVehiculeCount = createAsyncThunk(
  "vehicule/countWaiting",
  async (parametre) => {
    const {
      pagination = {},
      text = "",
      reserved = false,
      free = false,
      status = null,
      deepNumber = 2,
      user = null,
      startDate = null,
      endDate = null,
      sortBy = null,
      user_id = null,
    } = parametre || {};
    const { current = 1, pageSize = 10 } = pagination || {};

    const apiUrl = constructApiUrl({
      current,
      pageSize,
      text,
      reserved,
      free,
      status,
      deepNumber,
      user,
      startDate,
      endDate,
      sortBy,
      user_id,
    });

    try {
      const data = await fetchData(apiUrl);

      return { data: data, etat: free };
    } catch (error) {}
  }
);
// get one by id
export const getVehiculeById = createAsyncThunk("vehicule/one", async (id) => {
  try {
    const jwt = localStorage.getItem("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BACKUP_URL}vehicules/${id}?pLevel=4`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    return response?.data;
  } catch (error) {}
});
// add vehicule
export const addNewVehicule = createAsyncThunk(
  "vehicule/add",
  async (vehicule) => {
    try {
      const jwt = localStorage.getItem("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKUP_URL}vehicules`,
        vehicule,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// update vehicule

export const updateVehicule = createAsyncThunk(
  "vehicule/update",
  async ({ id, vehicule }) => {
    try {
      const jwt = localStorage.getItem("token");

      const response = await axios.put(
        `${process.env.REACT_APP_BACKUP_URL}vehicules/${id}`,
        vehicule,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// delete vehicule
export const deleteVehicule = createAsyncThunk(
  "vehicule/delete",
  async (id) => {
    try {
      const jwt = localStorage.getItem("token");

      const response = await axios.delete(
        `${process.env.REACT_APP_BACKUP_URL}vehicules/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  vehicules: [],
  getv: null,
  status: null,
  error: null,
  isLoading: false,
  meta: [],
  count: 0,
};

export const vehiculeSlice = createSlice({
  name: "vehicules",
  initialState,
  reducers: {},
  extraReducers: {
    [getVehiculeCount.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getVehiculeCount.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;

      state.count = action?.payload?.data?.data?.length;
    },
    [getVehiculeCount.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [getVehicule.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getVehicule.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.vehicules = action.payload?.data?.data;
      state.meta = action.payload?.data?.meta?.pagination;
    },
    [getVehicule.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [getVehiculeById.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getVehiculeById.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.getv = action.payload;
    },
    [getVehiculeById.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [addNewVehicule.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [addNewVehicule.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [addNewVehicule.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [updateVehicule.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [updateVehicule.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [updateVehicule.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [deleteVehicule.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [deleteVehicule.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [deleteVehicule.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
  },
});

export default vehiculeSlice.reducer;
