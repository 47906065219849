import OneSignal from "react-onesignal";
import notifSound from "./static/audio/notifSound.mp3";

import React, { useEffect, useState, useRef } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader/root";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import Admin from "./routes/admin";
import Auth from "./routes/auth";
import "./static/css/style.css";
import config from "./config/config";
import ProtectedRoute from "./components/utilities/protectedRoute";
import "antd/dist/antd.less";
import { getCurrentUser, getusers } from "./redux/User/userSlice";
import { getReservations } from "./redux/reservations/reservationSlice";

import "./static/css/style.css";
import ForgotPassword from "./container/profile/authentication/overview/ForgotPassword";
import axios from "axios";
import {
  getNotification,
  trigerNotificationsReducer,
} from "./redux/notifications/notificationSlice";
import { getPrices } from "./redux/pricing/settingSlice";
import packageJson from "../package.json";
const { theme } = config;

function ProviderConfig() {
  const bustCache = () => {
    const links = document.querySelectorAll(
      'link[rel="stylesheet"], script[src]'
    );
    const timestamp = new Date().getTime();

    links.forEach((link) => {
      const href = link.getAttribute("href") || link.getAttribute("src");
      link.setAttribute("href", `${href}?v=${timestamp}`);
    });
  };

  useEffect(() => {
    // clearCaching();
    let version = localStorage.getItem("version");
    if (version != packageJson.version) {
      bustCache();
      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
  }, []);
  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });
  const isLoggedIn = localStorage.getItem("token");
  const [path, setPath] = useState(window.location.pathname);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const [radar, setRadar] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getNotification({ id: currentUser?.id }));
      setRadar(true);
    }
  }, [currentUser?.id]);

  //----------------------onesignale----------------------------------------------

  const [notifTrigger, setNotifTrigger] = useState(false);

  const audioRef = useRef(null);

  const playSound = () => {
    audioRef.current.play();
  };

  useEffect(() => {
    const initializeOneSignal = async () => {
      const OneSignal = window.OneSignal || [];
      await OneSignal.init({
        appId: "2b6f2b0c-0070-4da7-b3c8-f8632a306bcb",
        safari_web_id: "web.onesignal.auto.4f716432-69c5-4efb-9ef6-8b17a5658ee8",
        autoResubscribe: true,
        notifyButton: {
          enable: true,
        },
      });

      // Check if the user is already subscribed
      const isSubscribed = await OneSignal.isPushNotificationsEnabled();

      if (!isSubscribed) {
        try {
          // Request permission to subscribe
          await OneSignal.registerForPushNotifications().then(() =>
            window.OneSignal.login(String(currentUser?.id))
          );
          // The user has granted permission and is now subscribed
          // You can handle this case as needed
        } catch (error) {
          // An error occurred or the user denied permission
          // You can handle this case as well
          console.error("Error registering for push notifications:", error);
        }
      }

      OneSignal.Slidedown.promptPush();
      // do other stuff
    };
    OneSignal.Notifications.addEventListener(
      "foregroundWillDisplay",
      (event) => {
        if (currentUser?.id) {
          dispatch(getNotification({ id: currentUser?.id }));
          playSound();
        }
        // console.log("notification recieved ====", event);
        setNotifTrigger(!notifTrigger);
        dispatch(trigerNotificationsReducer());
      }
    );
    if (isLoggedIn) {
      if (typeof window.OneSignal !== "undefined" && window.OneSignal.push) {
        window.OneSignal.push(() => {
          initializeOneSignal();
        });
      }
    }

    return () => {
      if (window.OneSignal?.initialized) {
        window.OneSignal.logout();
      }
    };
  }, [isLoggedIn]);

  //-------------------------------------------------------------------------------------

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUser()).then((result) => {
        window.OneSignal.login(String(result?.payload?.id));
      });
      // dispatch(getReservations());
    }
    // dispatch(getPrices());
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath, isLoggedIn]);

  /*  useEffect(() => {
    dispatch(
      getReservations({ free: true, deepNumber: 2, sortBy: "departDate:asc" })
    );
  }, []); */

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <audio style={{ display: "none" }} ref={audioRef} src={notifSound} />
        <Router basename={process.env.PUBLIC_URL}>
          <>
            {!isLoggedIn ? (
              <Route path="/" component={Auth} />
            ) : (
              <ProtectedRoute path="/admin" component={Admin} />
            )}
            {isLoggedIn &&
              (path === process.env.PUBLIC_URL ||
                path === `${process.env.PUBLIC_URL}/`) && (
                <Redirect to="/admin" />
              )}
          </>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return <ProviderConfig />;
}

export default hot(App);
