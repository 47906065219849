import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
// import { registerUser } from "../../redux/userSlice/userSlice";
import { useDispatch } from "react-redux";

import { UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, message, Upload } from "antd";
import axios from "axios";
import { logout, updateUser } from "../redux/User/userSlice";
import { useSelector } from "react-redux";

const Revalidate = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [accept, setAccept] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [image, setimage] = useState();
  // const [inputValue, setInputValue] = useState("");

  // const handleInputChange = (event) => {
  //   setInputValue(event.target.value);
  // };
  const myPromise = (data) =>
    Promise.resolve(dispatch(updateUser({ id: currentUser.id, user: data })));
  const [stepRegister, setstepRegister] = useState(true);

  //----------------------------------------------upload--------------------------------------------------------------------//
  const [pictures, setPictures] = useState({});

  useEffect(() => {
    if (currentUser) {
      setPictures({
        validation: { validation_state: "waiting" },
        accountOverview: [
          {
            __component: "section.company",

            kabis_picture: currentUser
              ? currentUser?.kabis_picture
              : null,
            assurance_rc_pro_picture: currentUser
              ? currentUser?.assurance_rc_pro_picture
              : null,
            cin_recto_picture: currentUser
              ? currentUser?.cin_recto_picture
              : null,
            cin_verso_picture: currentUser
              ? currentUser?.cin_verso_picture
              : null,
            licence_de_transport_picture: currentUser
              ? currentUser?.licence_de_transport_picture
              : null,
            rib_picture: currentUser
              ? currentUser?.rib_picture
              : null,
            assurance_rc_pro_date: currentUser
              ? currentUser?.assurance_rc_pro_date
              : null,
            licence_de_transport_date: currentUser
              ? currentUser?.licence_de_transport_date
              : null,
          },
        ],
      });
    }
  }, [currentUser]);

  const [picturesErrors, setPicturesErrors] = useState({
    kabis_picture: false,
    assurance_rc_pro_picture: false,
    cin_recto_picture: false,
    cin_verso_picture: false,
    licence_de_transport_picture: false,
    rib_picture: false,
    licence_de_transport_date: false,
    assurance_rc_pro_date: false,
  });

  const fileList = [
    {
      uid: "-1",
      name: currentUser?.logo
        ? currentUser?.logo?.name
        : "",
      status: "done",
      url: currentUser?.logo
        ? `${process.env.REACT_APP_BACKUP_URL}${currentUser?.logo?.url}`
        : "",

      // thumbUrl: currentUser?.logo?.name,
    },
  ];
  const fileListkabis = [
    {
      uid: "-1",
      name: currentUser?.kabis_picture
        ? currentUser?.kabis_picture[0]?.name
        : "",
      status: "done",
      url: currentUser?.kabis_picture
        ? `${process.env.REACT_APP_BACKUP_URL}${currentUser?.kabis_picture[0]?.url}`
        : "",

      // thumbUrl: currentUser?.logo?.name,
    },
  ];

  const fileListrc = [
    {
      uid: "-1",
      name: currentUser?.assurance_rc_pro_picture
        ? currentUser?.assurance_rc_pro_picture[0]?.name
        : "",
      status: "done",
      url: currentUser?.assurance_rc_pro_picture
        ? `${process.env.REACT_APP_BACKUP_URL}${currentUser?.assurance_rc_pro_picture[0]?.url}`
        : "",

      // thumbUrl: currentUser?.logo?.name,
    },
  ];

  const fileListcinRecto = [
    {
      uid: "-1",
      name: currentUser?.cin_recto_picture
        ? currentUser?.cin_recto_picture[0]?.name
        : "",
      status: "done",
      url: currentUser?.cin_recto_picture
        ? `${process.env.REACT_APP_BACKUP_URL}${currentUser?.cin_recto_picture[0]?.url}`
        : "",

      // thumbUrl: currentUser?.logo?.name,
    },
  ];

  const fileListcinVerso = [
    {
      uid: "-1",
      name: currentUser?.cin_verso_picture
        ? currentUser?.cin_verso_picture[0]?.name
        : "",
      status: "done",
      url: currentUser?.cin_verso_picture
        ? `${process.env.REACT_APP_BACKUP_URL}${currentUser?.cin_verso_picture[0]?.url}`
        : "",

      // thumbUrl: currentUser?.logo?.name,
    },
  ];
  const fileListLicenceTransport = [
    {
      uid: "-1",
      name: currentUser?.licence_de_transport_picture
        ? currentUser?.licence_de_transport_picture[0]?.name
        : "",
      status: "done",
      url: currentUser?.licence_de_transport_picture
        ? `${process.env.REACT_APP_BACKUP_URL}${currentUser?.licence_de_transport_picture[0]?.url}`
        : "",

      // thumbUrl: currentUser?.logo?.name,
    },
  ];
  const fileListRib = [
    {
      uid: "-1",
      name: currentUser?.rib_picture
        ? currentUser?.rib_picture[0]?.name
        : "",
      status: "done",
      url: currentUser?.rib_picture
        ? `${process.env.REACT_APP_BACKUP_URL}${currentUser?.rib_picture[0]?.url}`
        : "",

      // thumbUrl: currentUser?.logo?.name,
    },
  ];

  ///////////////////////////////upload 2 /////////////////////////////////////

  const handleFileSelect2 = async (e) => {
    const file = e;
    // .target.files[0];

    const isLt2MB = file.size / 1024 / 1024 < 1; // Limiting size to 2MB

    // console.log("file", file);
    const formData = new FormData();

    formData.append("files", file);

    const response = await axios
      .post(`${process.env.REACT_APP_BACKUP_URL}upload`, formData)
      .then((res) => {
        const updatedcompany = {
          ...pictures,
        };
        // Update the firstName property with the new input value
        updatedcompany.kabis_picture = res?.data[0];
        // Update the newUser state with the modified accountOverview
        setPictures({
          ...pictures,
          accountOverview: [updatedcompany],
        });
      });

    // Do something with the selected file
  };

  const fileUploadProps2 = {
    name: "files",
    multiple: false,
    beforeUpload: (file) => {
      handleFileSelect2(file);
      return false;
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        setimage({ ...image, file: info.file, list: info.fileList });
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    listType: "picture",
    defaultFileList: fileListkabis,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <FeatherIcon
          icon="trash-2"
        />
      ),
    },
  };

  ///////////////////////////////upload 3 /////////////////////////////////////

  const handleFileSelect3 = async (e) => {
    const file = e;
    // .target.files[0];

    const isLt2MB = file.size / 1024 / 1024 < 1; // Limiting size to 2MB

    // console.log("file", file);
    const formData = new FormData();

    formData.append("files", file);

    const response = await axios
      .post(`${process.env.REACT_APP_BACKUP_URL}upload`, formData)
      .then((res) => {
        const updatedcompany = {
          ...pictures,
        };
        // Update the firstName property with the new input value
        updatedcompany.assurance_rc_pro_picture = res?.data[0];
        // Update the newUser state with the modified accountOverview
        setPictures({
          ...pictures,
          accountOverview: [updatedcompany],
        });
      });

    // Do something with the selected file
  };

  const fileUploadProps3 = {
    name: "files",
    multiple: false,
    beforeUpload: (file) => {
      handleFileSelect3(file);
      return false;
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        setimage({ ...image, file: info.file, list: info.fileList });
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    listType: "picture",
    defaultFileList: fileListrc,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <FeatherIcon
          icon="trash-2"
        />
      ),
    },
  };

  ///////////////////////////////upload 4 /////////////////////////////////////

  const handleFileSelect4 = async (e) => {
    const file = e;
    // .target.files[0];

    const isLt2MB = file.size / 1024 / 1024 < 1; // Limiting size to 2MB

    // console.log("file", file);
    const formData = new FormData();

    formData.append("files", file);

    const response = await axios
      .post(`${process.env.REACT_APP_BACKUP_URL}upload`, formData)

      .then((res) => {
        const updatedcompany = {
          ...pictures,
        };
        // Update the firstName property with the new input value
        updatedcompany.cin_recto_picture = res?.data[0];
        // Update the newUser state with the modified accountOverview
        setPictures({
          ...pictures,
          accountOverview: [updatedcompany],
        });
      });
    // Do something with the selected file
  };

  const fileUploadProps4 = {
    name: "files",
    multiple: false,
    beforeUpload: (file) => {
      handleFileSelect4(file);
      return false;
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        setimage({ ...image, file: info.file, list: info.fileList });
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    listType: "picture",
    defaultFileList: fileListcinRecto,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <FeatherIcon
          icon="trash-2"
        />
      ),
    },
  };
  ///////////////////////////////upload 5 /////////////////////////////////////

  const handleFileSelect5 = async (e) => {
    const file = e;
    // .target.files[0];

    const isLt2MB = file.size / 1024 / 1024 < 1; // Limiting size to 2MB

    // console.log("file", file);
    const formData = new FormData();

    formData.append("files", file);

    const response = await axios
      .post(`${process.env.REACT_APP_BACKUP_URL}upload`, formData)
      .then((res) => {
        const updatedcompany = {
          ...pictures,
        };
        // Update the firstName property with the new input value
        updatedcompany.cin_verso_picture = res?.data[0];
        // Update the newUser state with the modified accountOverview
        setPictures({
          ...pictures,
          accountOverview: [updatedcompany],
        });
      });

    // Do something with the selected file
  };

  const fileUploadProps5 = {
    name: "files",
    multiple: false,
    beforeUpload: (file) => {
      handleFileSelect5(file);
      return false;
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        setimage({ ...image, file: info.file, list: info.fileList });
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    listType: "picture",
    defaultFileList: fileListcinVerso,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <FeatherIcon
          icon="trash-2"
        />
      ),
    },
  };

  ///////////////////////////////upload 6 /////////////////////////////////////

  const handleFileSelect6 = async (e) => {
    const file = e;
    // .target.files[0];

    const isLt2MB = file.size / 1024 / 1024 < 1; // Limiting size to 2MB

    // console.log("file", file);
    const formData = new FormData();

    formData.append("files", file);

    const response = await axios
      .post(`${process.env.REACT_APP_BACKUP_URL}upload`, formData)
      .then((res) => {
        const updatedcompany = {
          ...pictures,
        };
        // Update the firstName property with the new input value
        updatedcompany.rib_picture = res?.data[0];
        // Update the newUser state with the modified accountOverview
        setPictures({
          ...pictures,
          accountOverview: [updatedcompany],
        });
      });

    // Do something with the selected file
  };

  const fileUploadProps6 = {
    name: "files",
    multiple: false,
    beforeUpload: (file) => {
      handleFileSelect6(file);
      return false;
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        setimage({ ...image, file: info.file, list: info.fileList });
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    listType: "picture",
    defaultFileList: fileListRib,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <FeatherIcon
          icon="trash-2"
        />
      ),
    },
  };

  ///////////////////////////////upload 7 /////////////////////////////////////
  const handleFileSelect7 = async (e) => {
    const file = e;
    // .target.files[0];

    const isLt2MB = file.size / 1024 / 1024 < 1; // Limiting size to 2MB

    // console.log("file", file);
    const formData = new FormData();

    formData.append("files", file);

    const response = await axios
      .post(`${process.env.REACT_APP_BACKUP_URL}upload`, formData)
      .then((res) => {
        const updatedcompany = {
          ...pictures,
        };
        // Update the firstName property with the new input value
        updatedcompany.licence_de_transport_picture = res?.data[0];
        // Update the newUser state with the modified accountOverview
        setPictures({
          ...pictures,
          accountOverview: [updatedcompany],
        });
      });

    // Do something with the selected file
  };
  const fileUploadProps7 = {
    name: "files",
    multiple: false,
    beforeUpload: (file) => {
      handleFileSelect7(file);
      return false;
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        setimage({ ...image, file: info.file, list: info.fileList });
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    listType: "picture",
    defaultFileList: fileListLicenceTransport,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <FeatherIcon
          icon="trash-2"
        />
      ),
    },
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updateUser({ id: currentUser?.id, user: pictures }));
  };
  return (
    <FormContainer>
      <Formulaire onSubmit={handleUpdate}>
        <DISP>
          <Container>
            <Upload
              fileList={
                pictures?.accountOverview?.cin_recto_picture
                  ? [
                      {
                        uid: 1,
                        name: pictures?.accountOverview?.cin_recto_picture
                          ?.name,
                      },
                    ]
                  : []
              }
              accept="image/png, image/jpeg"
              multiple={false}
              {...fileUploadProps4}
              className="In"
              // {...register("pictures", {
              //   required: t("SINSCRIREpartenaire.validation.Activité"),
              // })}
              // errorBorder={errors.pictures}
            >
              <Button className="InBtn" icon={<UploadOutlined />}>
                Télécharger
              </Button>
            </Upload>

            <Label right={false}>Pièce d'identité recto</Label>
          </Container>
          <Container>
            <Upload
              fileList={
                pictures?.accountOverview?.cin_verso_picture
                  ? [
                      {
                        uid: 1,
                        name: pictures?.accountOverview?.cin_verso_picture
                          ?.name,
                      },
                    ]
                  : []
              }
              accept="image/png, image/jpeg"
              multiple={false}
              {...fileUploadProps5}
              className="In"
            >
              <Button className="InBtn" icon={<UploadOutlined />}>
                Télécharger
              </Button>
            </Upload>

            <Label right={false}>Pièce d'identité Verso</Label>
          </Container>
        </DISP>
        <DISP>
          <Container>
            <Upload
              fileList={
                pictures?.accountOverview?.kabis_picture
                  ? [
                      {
                        uid: 1,
                        name: pictures?.accountOverview?.kabis_picture?.name,
                      },
                    ]
                  : []
              }
              accept="image/png, image/jpeg"
              multiple={false}
              {...fileUploadProps2}
              className="In"
            >
              <Button className="InBtn" icon={<UploadOutlined />}>
                Télécharger
              </Button>
            </Upload>

            <Label right={false}>Photo Kbis</Label>
          </Container>
          <Container>
            <Upload
              fileList={
                pictures?.accountOverview?.assurance_rc_pro_picture
                  ? [
                      {
                        uid: 1,
                        name: pictures?.accountOverview
                          ?.assurance_rc_pro_picture?.name,
                      },
                    ]
                  : []
              }
              accept="image/png, image/jpeg"
              multiple={false}
              {...fileUploadProps3}
              className="In"
            >
              <Button className="InBtn" icon={<UploadOutlined />}>
                Télécharger
              </Button>
            </Upload>

            <Label right={false}>Photo Assurance RC Pro</Label>
          </Container>
        </DISP>
        <DISP>
          <Container>
            <Upload
              fileList={
                pictures?.accountOverview?.licence_de_transport_picture
                  ? [
                      {
                        uid: 1,
                        name: pictures?.accountOverview
                          ?.licence_de_transport_picture?.name,
                      },
                    ]
                  : []
              }
              accept="image/png, image/jpeg"
              multiple={false}
              {...fileUploadProps7}
              className="In"
            >
              <Button className="InBtn" icon={<UploadOutlined />}>
                Télécharger
              </Button>
            </Upload>

            <Label right={false}>licence de transport</Label>
          </Container>

          <Container>
            <Upload
              fileList={
                pictures?.accountOverview?.rib_picture
                  ? [
                      {
                        uid: 1,
                        name: pictures?.accountOverview?.rib_picture?.name,
                      },
                    ]
                  : []
              }
              accept="image/png, image/jpeg"
              multiple={false}
              {...fileUploadProps6}
              className="In"
            >
              <Button className="InBtn" icon={<UploadOutlined />}>
                Télécharger
              </Button>
            </Upload>

            <Label right={false}>RIB Société</Label>
          </Container>
        </DISP>

        <DISP>
          <Container>
            <DatePicker
              value={pictures?.assurance_rc_pro_date || null}
              onChange={(newDate) => {
                setPictures({
                  ...pictures,
                  accountOverview: [
                    {
                      ...pictures,
                      assurance_rc_pro_date: newDate,
                    },
                  ],
                });
              }}
            />

            <Label right={false}>Date expiration assurance rc pro</Label>
          </Container>
          <Container>
            <DatePicker
              onChange={(newDate) => {
                setPictures({
                  ...pictures,
                  accountOverview: [
                    {
                      ...pictures,
                      licence_de_transport_date: newDate,
                    },
                  ],
                });
              }}
            />
            {picturesErrors?.licence_de_transport_date && (
              <ErrorMessage>Obligatoire</ErrorMessage>
            )}

            <Label right={false}>Date expiration licence de transport</Label>
          </Container>
        </DISP>
        <div className="validate_user_actions">
          <Button
            size="default"
            type="primary"
            onClick={() => dispatch(logout())}
          >
            Retourner à la page d'accueil.
          </Button>
          <button
            className="InBtn"
            type="submit"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {" "}
            valider
          </button>{" "}
        </div>
      </Formulaire>
    </FormContainer>
  );
};

export default Revalidate;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  @media (max-width: 1050px) {
    margin-top: 5%;
    width: 95%;
  }
`;
const Formulaire = styled.form`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  @media (max-width: 1050px) {
    margin-top: 5%;
    width: 95%;
  } */
`;
const checkboxStyle = {
  width: "20px",
  height: "20px",
};

export const Pi = styled.p`
  color: var(--dark-main-color, #020111);
  font-size: 15px;
  font-family: "Inter", sans-serif;
  line-height: 150%;
  letter-spacing: 0.24px;

  @media (max-width: 1050px) {
    color: white;
  }
`;
export const DISPA = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  @media (max-width: 1050px) {
    /* display: none; */
  }
`;
export const DISPO = styled.section`
  display: none;
  @media (max-width: 1050px) {
    display: block;
    align-items: flex-start;
    gap: 16px;
  }
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: ${(props) =>
    props.errorBorder ? "1px solid #ff6961" : "1px solid #000"};
  outline: none;
  background-color: transparent;
  border-radius: 8px;
  transition: border-bottom-color 0.3s;
  @media (max-width: 1050px) {
    width: 100%;
    color: white;
    border-color: white;
    height: 60px;
    padding: 10px;
    &::placeholder {
      @media (max-width: 1050px) {
        color: #18365a;
      }
    }
  }
`;

export const Buttonn = styled.button`
  width: 70%;
  align-self: center;
  height: 45px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  background-color: black;
  margin-top: 20px;
  @media (max-width: 1050px) {
    color: #18365a;
    background-color: white;
    border: none;
    height: 60px;
    width: 60%;
    margin-bottom: 16px;
  }
`;
export const BTNn = styled.button`
  width: 100%;
  align-self: center;
  height: 45px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  background-color: black;
  margin-top: 20px;
  @media (max-width: 1050px) {
    color: #18365a;
    background-color: white;
    border: none;
    height: 60px;
    width: 60%;
    margin-bottom: 16px;
  }
`;

const Label = styled.label`
  position: absolute;
  top: 10px;
  /* left:10px; */
  left: ${(props) => (props.right ? "120px" : "10px")};
  font-size: 13px;
  color: #999;
  pointer-events: none;
  transition: transform 0.3s, color 0.3s;
  transform: translateY(-100%) translateX(-10%) scale(0.75);
  color: #000;
  background-color: white;
  padding: 0px 12px;
  width: max-content;
  @media (max-width: 1050px) {
    color: white;
    background-color: #18365a;
  }
`;
const Labell = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 13px;
  color: #999;
  pointer-events: none;
  transition: transform 0.3s, color 0.3s;
  transform: translateY(-100%) translateX(-10%) scale(0.75);
  color: #000;
  background-color: white;
  padding: 0px 12px;
  width: max-content;
  @media (max-width: 1050px) {
    color: white;
    background-color: #18365a;
    top: 10px;
    left: 12px;
  }
`;
export const DISP = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`;

const ErrorMessage = styled.span`
  color: #ff6961;
  padding: 6px 10px;
  max-width: 100%;
  font-size: 10px;
  overflow: hidden;
  align-self: flex-start;
`;
const In = styled.input`
  width: 100%;
  height: 45px;
  font-size: 16px;
  padding: 10px;
  border: ${(props) =>
    props.errorBorder ? "1px solid #ff6961" : "1px solid #000"};
  background-color: transparent;
  border-radius: 8px;
  transition: border-bottom-color 0.3s;

  &[type="file"]::file-selector-button {
    border: none;
    background: #18365a;
    padding: 3px 7px;
    font-size: 13px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  &::file-selector-button:hover {
    background-color: #18365a;
  }
  @media (max-width: 1050px) {
    width: 100%;
    color: white;
    border-color: white;
    height: 60px;
    &::placeholder {
      @media (max-width: 1050px) {
        color: #18365a;
      }
    }
  }
`;

const Buttonnn = styled.button`
  width: 100%;
  height: 45px;
  font-size: 16px;
  padding: 10px;
  border: ${(props) =>
    props.errorBorder ? "1px solid #ff6961" : "1px solid #000"};
  background-color: transparent;
  border-radius: 8px;
  transition: border-bottom-color 0.3s;

  &[type="file"]::file-selector-button {
    border: none;
    background: #18365a;
    padding: 3px 7px;
    font-size: 13px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  &::file-selector-button:hover {
    background-color: #18365a;
  }
  @media (max-width: 1050px) {
    width: 100%;
    color: white;
    border-color: white;
    height: 60px;
    &::placeholder {
      @media (max-width: 1050px) {
        color: #18365a;
      }
    }
  }
`;
const Prénom = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: transform 0.3s, color 0.3s;
  transform: translateY(-100%) translateX(-10%) scale(0.75);
  color: #000;
  background-color: white;
  padding: 0px 12px;
  @media (max-width: 1050px) {
    color: white;
    background-color: #18365a;
  }
`;
