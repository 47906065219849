import React, {
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Skeleton, Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Dashboard from "./dashboard";

import withAdminLayout from "../../layout/withAdminLayout";
import { useSelector } from "react-redux";
import ValidationOverlay from "../../ValidationOverlay/ValidationOverlay";
import NotFound from "../../container/pages/404";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../redux/User/userSlice";
import HistoriqueRoute from "./Historique";

const Livreurs = lazy(() => import("./Livreur"));

const Reservations = lazy(() => import("./Reservations"));

const Commandes = lazy(() => import("./Commandes"));
const CommandProfile = lazy(() => import("./CommandProfile"));

const Vehicules = lazy(() => import("./Vehicules"));

const ListSociété = lazy(() => import("./Société"));

const ClientsRoutes = lazy(() => import("./Client"));
const AgentsRoutes = lazy(() => import("./Agent"));
const AdminsRoutes = lazy(() => import("./Admin"));
const livreurMap = lazy(() => import("./MapLivreur"));
const CalculeRoutes = lazy(() => import("./Calcule"));
const MaintenanceRoutes = lazy(() => import("./Maintenance"));
const ObjetsRoutes = lazy(() => import("./Objets"));
const BalanceRoutes = lazy(() => import("./Balance"));
const Historique = lazy(() => import("./Historique"));
const NotFoundPage = lazy(() => import("../../container/pages/404.js"));

const Admin = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const userValidation = useSelector((state) => state.user?.currentUser);

  const validation = useMemo(() => {
    if (userValidation === null) {
      return null;
    }

    // Calculate the validation state based on userValidation
    if (userValidation?.validation?.validation_state === "valid") {
      return "valid";
    } else if (userValidation?.validation?.validation_state === "invalid") {
      return "invalid";
    } else if (userValidation?.validation?.validation_state === "waiting") {
      return "waiting";
    }
  }, [userValidation]);

  const isLoggedIn = localStorage.getItem("token");

  useLayoutEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUser());
    }
  }, []);

  const renderRoutes = () => (
    <>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <ProtectedRoute
          path={path}
          component={Dashboard}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />

        <ProtectedRoute
          path={`${path}/reservations`}
          component={Reservations}
          allowedRoles={["owner", "admin"]}
        />
        <ProtectedRoute
          path={`${path}/commandes`}
          component={Commandes}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />
        <ProtectedRoute
          path={`${path}/details`}
          component={CommandProfile}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />

        <ProtectedRoute
          path={`${path}/Vehicules`}
          component={Vehicules}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />

        <ProtectedRoute
          path={`${path}/clients`}
          component={ClientsRoutes}
          allowedRoles={["owner", "admin"]}
        />
        <ProtectedRoute
          path={`${path}/Agents`}
          component={AgentsRoutes}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />
        <ProtectedRoute
          path={`${path}/track`}
          component={livreurMap}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />

        <ProtectedRoute
          path={`${path}/Societes`}
          component={ListSociété}
          allowedRoles={["owner", "admin"]}
        />
        <ProtectedRoute
          path={`${path}/Livreurs`}
          component={Livreurs}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />
        <ProtectedRoute
          path={`${path}/Admins`}
          component={AdminsRoutes}
          allowedRoles={["owner", "admin"]}
        />
        <ProtectedRoute
          path={`${path}/Calcule`}
          component={CalculeRoutes}
          allowedRoles={["owner", "admin"]}
        />
        <ProtectedRoute
          path={`${path}/Maintenance`}
          component={MaintenanceRoutes}
          allowedRoles={["owner", "admin"]}
        />
        <ProtectedRoute
          path={`${path}/Objets`}
          component={ObjetsRoutes}
          allowedRoles={["owner", "admin"]}
        />
        <ProtectedRoute
          path={`${path}/balance`}
          component={BalanceRoutes}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />
        <ProtectedRoute
          path={`${path}/Historique`}
          component={Historique}
          allowedRoles={["owner", "admin", "company", "agent"]}
        />
      </Suspense>
    </>
  );
  return (
    <Switch>
      {validation === null ? (
        <div className="app_with_overlay_spinner">
          <Spin size="large" />
        </div>
      ) : validation !== "valid" ? (
        <div className="app_with_overlay">
          <ValidationOverlay validation={validation} />
        </div>
      ) : (
        renderRoutes() // Render your authenticated routes
      )}
    </Switch>
  );
};

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const currentUser = useSelector((state) => state.user?.currentUser);
  const userRole = currentUser?.user_role;

  if (
    allowedRoles &&
    allowedRoles.length > 0 &&
    !allowedRoles.includes(userRole)
  ) {
    if (!userRole) {
      return <Skeleton />;
    } else {
      return <Route {...rest} render={(props) => <NotFound />} />;
    }
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default withAdminLayout(Admin);
