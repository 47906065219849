import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
query UsersPermissionsUsers_connection($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  usersPermissionsUsers_connection(filters: $filters, pagination: $pagination, sort: $sort) {
    nodes {
      documentId
      email
      user_role
      phoneNumber
      username
      firstName
      lastName
      createdAt
      updatedAt
      confirmed
      blocked
    }
    pageInfo {
      page
      pageSize
      total
    }
  }
}
`;

export const GET_VEHICULES_LIST = gql`
query Vehicules {
  vehicules {
    color
    createdAt
    matriculation
    model
    assuranceDate
    vinNumber
    year
    mark
    assurancePictures {
      url
    }
    grayCardPictures {
      url
    }
    vehiculePictureface1 {
      url
    }
    vehiculePictureface2 {
      url
    }
    vehiculePictureface3 {
      url
    }
    vehiculePictureface4 {
      url
    }
  }
}
`