import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// get all objects

const constructApiUrl = ({
  current = 1,
  pageSize = 10,
  text = "",

  deepNumber = 3,
}) => {
  const apiUrl = new URL(`${process.env.REACT_APP_BACKUP_URL}objets`);
  //
  apiUrl.searchParams.append("pLevel", `${deepNumber}`);
  //
  apiUrl.searchParams.append("pagination[pageSize]", pageSize);
  //
  apiUrl.searchParams.append("pagination[page]", current);
  //
  if (text) {
    apiUrl.searchParams.append("filters[$or][0][objet][name][$contains]", text);
  }
  if (text) {
    apiUrl.searchParams.append(
      "filters[$or][1][objet][category][$contains]",
      text
    );
  }

  //${process.env.REACT_APP_BACKUP_URL}commands?filters[departDate][$gte][0]=2023-07-14&filters[departDate][$lte][1]=2023-07-20
  // apiUrl.searchParams.append("sort", ":desc");
  return apiUrl.toString();
};

// Function to fetch data
const fetchData = async (url) => {
  let jwt = localStorage.getItem("token");
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getObjet = createAsyncThunk("objet/all", async (parametre) => {
  let jwt = localStorage.getItem("token");

  const {
    pagination = {},
    text = null,
    reserved = false,
    free = false,
    status = null,
    deepNumber = 4,
    user = null,
    startDate = null,
    endDate = null,
    sortBy = null,
    user_id = null,
  } = parametre || {};
  const { current = 1, pageSize = 10 } = pagination || {};

  const apiUrl = constructApiUrl({
    current,
    pageSize,
    text,

    deepNumber,
  });

  try {
    const data = await fetchData(apiUrl);

    return data;
  } catch (error) {}
});
// get one by id
export const getObjetById = createAsyncThunk("objet/one", async (id) => {
  let jwt = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKUP_URL}objets/${id}?pLevel=2&pagination[start]=0`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    return response.data;
  } catch (error) {}
});
export const getObjetAll = createAsyncThunk("objet/allobj", async () => {
  let jwt = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKUP_URL}objets?pLevel=3&?pagination[start]=0&pagination[limit]=999999`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    return response.data;
  } catch (error) {}
});
// add objet
export const addNewObjet = createAsyncThunk("objet/add", async (objet) => {
  let jwt = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKUP_URL}objets`,
      objet,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
});

// update objet

export const updateObjet = createAsyncThunk(
  "objet/update",
  async ({ id, objet }) => {
    let jwt = localStorage.getItem("token");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKUP_URL}objets/${id}`,
        objet,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// delete objet
export const deleteObjet = createAsyncThunk("objet/delete", async (id) => {
  let jwt = localStorage.getItem("token");

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKUP_URL}objets/${id}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  objets: [],
  geto: null,
  status: null,
  error: null,
  isLoading: false,
  meta: [],
};

export const objetSlice = createSlice({
  name: "objets",
  initialState,
  reducers: {},
  extraReducers: {
    [getObjet.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getObjet.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.objets = action.payload?.data;
      state.meta = action.payload?.meta;
    },
    [getObjet.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [getObjetAll.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getObjetAll.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.objets = action.payload.data;
    },
    [getObjetAll.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [getObjetById.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getObjetById.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.geto = action.payload;
    },
    [getObjetById.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [addNewObjet.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [addNewObjet.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [addNewObjet.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [updateObjet.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [updateObjet.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [updateObjet.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
    [deleteObjet.pending]: (state) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [deleteObjet.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [deleteObjet.rejected]: (state) => {
      state.status = "fail";
      state.isLoading = false;
      state.error = "fail";
    },
  },
});

export default objetSlice.reducer;
